const LOG_PREFIX = '[OCM][UAM] '

module.exports = class UAM {
    utils
    config
    uam_config

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.uam_config = config.services.uam
    }

    init() {
        if (this.config.debug || this.uam_config.debug) {
            console.log(LOG_PREFIX + 'Initializing...')
        }

        !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",this.utils.window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");
        apstag.init({
            pubID: this.uam_config.pubID,
            adServer: this.uam_config.adServer,
            bidTimeout: this.uam_config.bidTimeout,
            schain: this.config.schain.config
        })
    }

    async fetch(slots) {
        slots = (!slots.length) ? this.config.services.header_bidding.adUnits : slots

        await this.formatUamSlots(slots).then(uamSlots => {
            slots = uamSlots.filter((slot) => {
                return (slot !== null)
            })
        })

        if (this.config.debug || this.uam_config.debug) {
            console.log(LOG_PREFIX + 'Fetching slots from config according to supplied slots', slots)
        }

        if (slots.length) {
            if (this.config.debug || this.uam_config.debug) {
                console.log(LOG_PREFIX + 'fetching bids for ', slots)
            }

            apstag.fetchBids({
                slots: slots,
                timeout: this.uam_config.timeout
            }, (bids) => {
                this.utils.window.googletag.cmd.push(() => {
                    apstag.setDisplayBids()
                    this.utils.window.OCM.uam_done = true
                })
            })
        }
    }

    async formatUamSlots(adunits) {
        return new Promise((resolve, reject) => {
            let uam_slots = []
            let slotID

            let end = (uam_slots) => {
                if (uam_slots.length) {
                    resolve(uam_slots)
                } else {
                    reject()
                }
            }

            this.utils.window.googletag = this.utils.window.googletag || {cmd:[]}

            this.utils.gptPubAdsReady().then(() => {
                let slots = this.utils.window.googletag.pubads().getSlots()
                uam_slots = adunits.map((adunit) => {
                    slotID = null
                    if (this.utils.allowPageType(adunit.page_types)) {
                        slotID = slots.find((slot) => slot.getAdUnitPath() === adunit.code)

                        if (!slotID) {
                            return null
                        }

                        return {
                            slotID: slotID.getSlotElementId(),
                            slotName: adunit.code,
                            sizes: (typeof adunit.sizes === 'object') ? adunit.sizes : JSON.parse(adunit.sizes)
                        }
                    } else {
                        return null;
                    }
                })

                end(uam_slots)
            })
        })
    }
}
