const LOG_PREFIX = '[OCM][Ads behind Adblock] '

let blockthrough = (config) => {
    // Create a script element to load Blockthrough script
    let btScript = document.createElement('script');
    // Update src based on link given to you by your PS manager
    btScript.src = 'https://cdn.unblockia.com/h.js';
    // Insert script in the head
    document.head.appendChild(btScript);

    if (config.debug || config.services.blockthrough.debug) {
        console.info(LOG_PREFIX + 'Loaded')
    }
};

module.exports = blockthrough;
