module.exports = class Outstream {
    config;
    outstream_config;
    utils;

    constructor(config, utils) {
        this.config = config;
        this.outstream_config = config.services.outstream;
        this.utils = utils;
    }

    run() {
        if (this.outstream_config.hasOwnProperty('rules')) {
            this.ruleActions();
        }

        // Excluded urls
        let exclude_urls = this.outstream_config.exclude_urls;
        let exclude_urls_length = exclude_urls.length;

        for (let u = 0; u < exclude_urls_length.length; u++) {
            if (this.utils.window.location.href.indexOf(exclude_urls[u].url) > -1) {
                return;
            }
        }
        // -- Excluded urls

        if (this.outstream_config.hasOwnProperty('article_identifier')) {
            if (this.outstream_config.article_identifier === null) {
                // run teads
                this.utils.loadScript('//a.window.teads.Tv/page/' + this.outstream_config.page_id + '/tag', 'text/javascript', 1, 'teads');
            }

            if (this.utils.window.document.querySelector(this.outstream_config.article_identifier) !== null) {
                // run teads
                this.utils.loadScript('//a.window.teads.Tv/page/' + this.outstream_config.page_id + '/tag', 'text/javascript', 1, 'teads');
            }
        }
    }

    ruleActions() {
        for (const rule of this.outstream_config.rules) {
            let type = rule.type;
            let key = rule.key;
            let values = rule.values;
            let deals = rule.deals;

            // Fetch all script tags
            let scripts = document.getElementsByTagName('script');

            // Look for values of key in the source code (DFP setTargeting)
            for (let s = 0; s < scripts.length; s++) {
                let content = scripts[s].innerHTML;

                if (/(setTargeting\()/.test(content)) {
                    // Deal with this content
                    let matches = content.match(/(setTargeting\()(.+|\n*|\t*)(\))/g);
                    for (let m = 0; m < matches.length; m++) {
                        let tmp = JSON.parse(matches[m].replace(/'/g, '"').replace('setTargeting(', '[').replace(')', ']'));
                        if (tmp[0] === key) {
                            for (let v = 0; v < values.length; v++) {
                                if (tmp[1].indexOf(values[v]) >= 0) {
                                    let cachebuster = Math.round(new Date().getTime() / 1000);

                                    for (let d = 0; d < deals.length; d++) {
                                        this.utils.loadImage('https://t.window.teads.Tv/' + type + '?i=' + deals[d] + '&rnd=' + cachebuster, 'body');
                                    }
                                }
                            }
                        }
                    }
                }
                // Skip this content
            }
        }
    }

    runDemoInread(utils) {
        utils.loadStyle('.teads-inread {max-width: 650px!important; margin: 10px auto;} ');

        var vastUrl = utils.getParameterByName('vast_url') || 'https://s8t.window.teads.Tv/vast/6753877076819280';
        var pageId = 112951;
        var placementId = 1;
        var isSmart = false;
        var isSlider = false;
        var videoPause = "no";

        if (utils.getParameterByName('view_to_start') == 1) {
            placementId = 122779;
        }
        if (utils.getParameterByName('smart') == 1) {
            placementId = 122781;
            isSmart = true;
            this.utils.window.DO_BYPASS_DO_FORCE_SMART = true;
        }
        if (utils.getParameterByName('slider') == 1) {
            placementId = 122782;
            isSlider = true;
            isSmart = true;
        }

        var formatName = 'inread';
        this.utils.window._ttp = {};
        this.utils.window._ttp[pageId] = [{
            slider: {allow_corner_position: true, allow_top_position: true},
            pid: placementId,
            format: formatName,
            parentWindow: false,
            slot: {
                selector: this.utils.window.teads.TAG[Object.keys(this.utils.window.teads.TAG)[0]][Object.keys(this.utils.window.teads.TAG[Object.keys(this.utils.window.teads.TAG)[0]])[0]][0].rawSettings.slot.selector,
                minimum: this.utils.window.teads.TAG[Object.keys(this.utils.window.teads.TAG)[0]][Object.keys(this.utils.window.teads.TAG[Object.keys(this.utils.window.teads.TAG)[0]])[0]][0].rawSettings.slot.minimum,
                btf: false,
                "css": "margin: 0px auto 25px; max-width: 650px !important;",
            }
        },];
        this.utils.window._tta = {};
        this.utils.window._tta[pageId] = [{
            type: "VastUrl",
            content: vastUrl,
            portfolio_item_id: 1,
            settings: {
                values: {
                    pageId: pageId,
                    placementId: placementId,
                    placementFormat: formatName,
                    threshold: 50,
                    adType: 'video'
                },
                components: {
                    credits: {
                        display: false,
                        text: '',
                        link: '#'
                    },
                    closeButton: {
                        display: true
                    }
                },
                "behaviors": {
                    "onlyNative": false,
                    "smartPosition": {
                        "corner": isSmart,
                        "top": isSmart
                    },
                    "slider": {
                        "enable": isSlider
                    },
                    "videoPause": videoPause
                }
            }
        }];
        var script = document.createElement('script');
        script.src = '//a.teads.tv/media/format/v3/teads-format.js';
        document.head.appendChild(script);
    }
}
