const LOG_PREFIX = '[OCM][VI] '

module.exports = class VI {
    utils
    config
    vi_config

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.vi_config = (this.utils.is_mobile) ? this.config.services.vi.mobile : this.config.services.vi.desktop
    }

    run() {
        if (this.config.debug || this.config.services.vi.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }
        // Hide VI icon
        this.utils.loadStyle('#vi-stories-sponsor-container {display: none!important;}')

        this.createElement()
    }

    createElement() {
        let element = document.createElement('div')
        element.id = 'vi-div'
        element.style = this.config.services.vi.style

        let node = this.utils.determineInjectionTarget(this.vi_config.conditions.selector, this.vi_config.conditions.position, this.vi_config.conditions.count_gt, this.vi_config.conditions.word_count, this.vi_config.conditions.words_gt)
        if (!node) {
            if (this.config.debug || this.config.services.vi.debug) {
                console.warn(LOG_PREFIX + 'Could not find selector ' + this.vi_config.conditions.selector)
            }
            return
        }

        if (this.utils.injectTag(node, element, this.vi_config.conditions.place)) {
            this.viScript()
        }
    }

    viScript() {
        let v = document
        let i = this.utils.window

        let scp = v.createElement("script"),
            config = {
                ChannelID: '5d8390ef28a0611e523a52aa',
                AdUnitType: '2',
                PublisherID: '133044126840134',
                PlacementID: 'pltz9FljnNNm8YU6PEn',
                DivID: 'vi-div',
                IAB_Category: this.config.services.vi.iab,
                Keywords: '', // TODO: talk with VI if anything happens if these are changed
                Language: 'en-us', // TODO: talk with VI if anything happens if these are changed
                BG_Color: '', // TODO: talk with VI if anything happens if these are changed
                Text_Color: '', // TODO: talk with VI if anything happens if these are changed
                Font: '', // TODO: talk with VI if anything happens if these are changed
                FontSize: '', // TODO: talk with VI if anything happens if these are changed
            };
        scp.src = 'https://s.vi-serve.com/tagLoader.js';
        scp.type = "text/javascript";
        scp.onload = function () {
            i[btoa('video intelligence start')].init(config);
        };
        (v.getElementsByTagName('head')[0] || v.documentElement.appendChild(v.createElement('head'))).appendChild(scp);
    }
}
