const LOG_PREFIX = '[OCM][MIS] '

module.exports = class MobileInreadSticky {
    utils
    config
    mis_config
    pairs

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.mis_config = config.services.mobile_inread_sticky
    }

    run() {
        this.utils.loadStyle('.ocm-mis-adv{font-size:10px; margin:0 auto 5px 0} .ocm-mis{position:relative;text-align:center} .ocm-mis-expand{height:650px; min-width:300px; max-width:336px; background-color:transparent; margin:0 auto;} .ocm-mis-wrapper{position:sticky; top:' + this.mis_config.top + 'px !important}')
        this.gptMisEvent()
        this.utils.contentLoaded().then(() => {
            this.init();
        });
    }

    init() {
        if (this.config.debug || this.mis_config.debug) {
            console.log(LOG_PREFIX + 'Looking up configured MIS divs...');
        }

        for(const pair of this.mis_config.pairs) {
            if (!this.utils.allowPageType(pair.page_types)) {
                if (this.config.debug || this.mis_config.debug) {
                    console.log(LOG_PREFIX + '##' + pair.selector + '## not allowed in page type');
                }
                continue
            }

            let mis_unit = this.utils.window.document.querySelector('[id*="' + pair.selector + '"]:not([data-ocm-mis="true"])');
            if (mis_unit) {
                if (this.config.debug || this.mis_config.debug) {
                    console.log(LOG_PREFIX + 'Div found (' + pair.selector +')');
                }

                if (mis_unit.querySelector('div[id^="google_ads_iframe_"] > iframe')) {
                    if (this.config.debug || this.mis_config.debug) {
                        console.log(LOG_PREFIX + 'Ad already loaded. Don\'t mess with it. Aborting DOM manipulation.');
                    }

                    return;
                }

                if (this.config.debug || this.mis_config.debug) {
                    console.log(LOG_PREFIX + 'Transforming to mobile inread sticky (' + pair.selector +')');
                }

                // Disabling this as it breaks the new functionality
                // let mis_adv;

                // if (this.mis_config.show_text) {
                //     mis_adv = document.createElement('p');
                //     mis_adv.classList.add('ocm-mis-adv');
                //     mis_adv.innerText = 'ΔΙΑΦΗΜΙΣΗ';
                // }

                let mis = document.createElement('div');
                mis.classList.add('ocm-mis');

                let mis_expand = document.createElement('div')
                mis_expand.classList.add('ocm-mis-expand')

                let mis_wrapper = document.createElement('div')
                mis_wrapper.classList.add('ocm-mis-wrapper')

                // insert mis before mis_unit in the DOM tree
                mis_unit.parentNode.insertBefore(mis, mis_unit);

                mis.append(mis_expand)
                mis_expand.append(mis_wrapper)

                // if (this.mis_config.show_text === true) {
                //     mis_wrapper.appendChild(mis_adv);
                // }

                // move mis_unit into mis
                mis_wrapper.appendChild(mis_unit);

                mis_unit.setAttribute('data-ocm-mis', 'true');

                this.utils.clipHiddenOverflows(mis)

                if (this.config.debug || this.mis_config.debug) {
                    console.log(LOG_PREFIX + 'Transformation done (' + pair.selector +')');
                }
            }
        }
    }

    gptMisEvent() {
        this.utils.window.googletag.cmd.push(() => {
            googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                if (event.isEmpty) {
                    return
                }

                if (this.utils.is_mobile) {
                    if (this.config.debug || this.mis_config.debug) {
                        console.log(LOG_PREFIX + 'Am in mobile device, time to check for position + z-index properties')
                    }

                    let slot_el = document.getElementById(event.slot.getSlotElementId())

                    if (this.config.debug || this.mis_config.debug) {
                        console.log(LOG_PREFIX + 'Found non empty mis slot:', slot_el, slot_el.classList.contains('ocm-mis'))
                    }

                    if (slot_el.classList.contains('ocm-mis')) {
                        let waitForPositionRelativeCount = 20;
                        let waitForPositionRelative = () => {
                            if (this.config.debug || this.mis_config.debug) {
                                console.log(LOG_PREFIX + 'Looking for position style property in ' + '#' + slot_el.id)
                            }
                            if (slot_el.style.getPropertyValue('position') && waitForPositionRelativeCount > 0) {
                                if (this.config.debug || this.mis_config.debug) {
                                    console.log(LOG_PREFIX + 'Found position style property in ' + '#' + slot_el.id)
                                }
                                if (slot_el.style.removeProperty) {
                                    if (this.config.debug || this.mis_config.debug) {
                                        console.log(LOG_PREFIX + 'Removing position style property in ' + '#' + slot_el.id)
                                    }
                                    slot_el.style.removeProperty('position')
                                }
                            } else {
                                if (this.config.debug || this.mis_config.debug) {
                                    console.log(LOG_PREFIX + 'Did not find position style property in ' + '#' + slot_el.id + ', retrying')
                                }
                                if (waitForPositionRelativeCount > 0) {
                                    waitForPositionRelativeCount--
                                    setTimeout(waitForPositionRelative, 100)
                                }
                            }
                        }
                        waitForPositionRelative()
                    }
                }
            })
        })
    }
}
