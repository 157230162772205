const LOG_PREFIX = "[OCM][PageSense] "
let pageSense = async (utils) => {
  return new Promise((resolve, reject) => {
    let pageType = 'ROS';
    let subPaths = window.location.pathname.replace("/", "").split("/") || [];
    let customPaths = []
    let sponsoredStories = [ // block only injected ads
      '1806110', '1809205', '1803544', '1801442', '1803160', '1804516', '1803552',
      '1813422','1825840','1826038','1836175',
    ]
    let blocked = [ // block everything
      '1811178','1819225','1851849','1855214','1879375','1880754','1880754','1883832','1909406','1912481','1912865',
    ]

    // Keep only String values in subPaths (removes null and empty)
    subPaths = subPaths.filter(String);
    // The actual page sense goes here
    if (!subPaths.length) {
      pageType = "Home"
    } else if (subPaths.length === 1 && subPaths[0] !== 'story') {
      pageType = "Category"
    } else if (subPaths.length >= 2 && (subPaths[0] === 'story' || subPaths[1] === 'story')) {
      let storyId = (subPaths[0] === 'story') ? subPaths[1] : subPaths[2]
      if (sponsoredStories.includes(storyId)) {
        pageType = 'sponsored'
      } else if (blocked.includes(storyId)) {
        pageType = 'blocked'
      } else {
        pageType = "Article"
      }
    }

    window.googletag = window.googletag || {cmd: []}

    if (pageType !== 'blocked') {
      utils.contentLoaded().then(() => {
        window.googletag.cmd.push(() => {
          googletag.pubads().setTargeting('page', [pageType])
          if (window.pageSection) {
            googletag.pubads().setTargeting('category', [window.pageSection])
          }
          if (window.storyAssetId) {
            googletag.pubads().setTargeting('storyId', [window.storyAssetId])
          }
        })

        defineSlots(utils, pageType)

        let ad_placements = document.querySelectorAll('div[data-ocm-ad]')
        ad_placements.forEach((ap) => {
          window.googletag.cmd.push(() => { googletag.display(ap.id) })
        })
      })
    }

    resolve(pageType)
  });
};

let defineSlots = (utils, pageType) => {

  window.googletag.cmd.push(() => {

    var billboard1 = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([340,0], [[300,250]])
    .addSize([768,0], [[728,90]])
    .addSize([1024,0], [[970,250],[970,90],[728,90]])
    .build();

    var billboard = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([768,0], [[728,90]])
    .addSize([1024,0], [[970,250],[970,90],[728,90]])
    .build();

    var sidebar = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([1024,0], [[300,250],[300,600]])
    .build();

    var inline1 = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([320,0], [[300,250], [300,600]])
    .addSize([360,0], [[300,250], [336,280], [300,600]])
    .addSize([768,0], [[300,250], [336,280]])
    .build();

    var inline = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([320,0], [[300,250]])
    .addSize([360,0], [[300,250], [336,280]])
    .build();

    var infeed = googletag.sizeMapping()
    .addSize([0,0], [])
    .addSize([320,0], [[300,250], [300,600]])
    .addSize([360,0], [[300,250], [336,280], [300,600]])
    .addSize([768,0], [])
    .build();

    googletag.defineSlot('/1024786/naftemporiki.gr/billboard1', [[300,250],[728,90],[970,90],[970,250]], 'billboard1').defineSizeMapping(billboard1).addService(googletag.pubads())

    if (!utils.is_mobile) {
      googletag.defineSlot('/1024786/naftemporiki.gr/sponsorship', [300,50], 'sponsorship').addService(googletag.pubads())
    }
    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_news_1', [1,1], 'traffic_driver_news_1').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_news_2', [1,1], 'traffic_driver_news_2').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_1', [1,1], 'traffic_driver_side_1').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_2', [1,1], 'traffic_driver_side_2').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_3', [1,1], 'traffic_driver_side_3').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/billboard2', [[728,90],[970,90],[970,250]], 'billboard2').defineSizeMapping(billboard).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/billboard3', [[728,90],[970,90],[970,250]], 'billboard3').defineSizeMapping(billboard).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/sidebar1', [[300,250],[300,600]], 'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/sidebar2', [[300,250],[300,600]], 'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/sidebar3', [[300,250],[300,600]], 'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/sidebar4', [[300,250],[300,600]], 'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/infeed1', [[300,250],[336,280],[300,600]], 'infeed1').defineSizeMapping(infeed).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/infeed2', [[300,250],[336,280],[300,600]], 'infeed2').defineSizeMapping(infeed).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/infeed3', [[300,250],[336,280],[300,600]], 'infeed3').defineSizeMapping(infeed).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/infeed4', [[300,250],[336,280],[300,600]], 'infeed4').defineSizeMapping(infeed).addService(googletag.pubads())

    googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_ros', [1,1], 'traffic_driver_ros').addService(googletag.pubads())

    googletag.defineSlot('/1024786/naftemporiki.gr/article_end', [[300,250],[336,280]], 'article_end').defineSizeMapping(inline).addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_1', [1,1], 'story_tlink_1').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_2', [1,1], 'story_tlink_2').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_3', [1,1], 'story_tlink_3').addService(googletag.pubads())

    googletag.defineSlot('/1024786/naftemporiki.gr/mtfcompanyin', [100,30], 'mtfcompanyin').addService(googletag.pubads())
    googletag.defineSlot('/1024786/naftemporiki.gr/mtfcompaniesext', [300,100], 'mtf_companies_ext').addService(googletag.pubads())
  })
}

// let defineSlots_old = (utils, pageType) => {

//   window.googletag.cmd.push(() => {

//     var billboard1 = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([340,0], [[320,50],[320,100]])
//     .addSize([768,0], [[728,90]])
//     .addSize([1024,0], [[970,250],[970,90],[728,90]])
//     .build();

//     var billboard = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([768,0], [[728,90]])
//     .addSize([1024,0], [[970,250],[970,90],[728,90]])
//     .build();

//     var sidebar = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([1024,0], [[300,250],[300,600]])
//     .build();

//     var inline1 = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([320,0], [[300,250], [300,600]])
//     .addSize([360,0], [[300,250], [336,280], [300,600]])
//     .addSize([768,0], [[300,250], [336,280]])
//     .build();

//     var inline = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([320,0], [[300,250]])
//     .addSize([360,0], [[300,250], [336,280]])
//     .build();

//     var infeed = googletag.sizeMapping()
//     .addSize([0,0], [])
//     .addSize([320,0], [[300,250], [300,600]])
//     .addSize([360,0], [[300,250], [336,280], [300,600]])
//     .addSize([768,0], [])
//     .build();

//     googletag.defineSlot('/1024786/naftemporiki.gr/billboard1', [[320,50],[320,100],[728,90],[970,90],[970,250]], 'billboard1').defineSizeMapping(billboard1).addService(googletag.pubads())

//     switch(pageType) {
//       case 'Home':
//         if (!utils.is_mobile) {
//           googletag.defineSlot('/1024786/naftemporiki.gr/sponsorship', [300,50], 'sponsorship').addService(googletag.pubads())
//         }
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_news_1', [1,1], 'traffic_driver_news_1').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_news_2', [1,1], 'traffic_driver_news_2').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_1', [1,1], 'traffic_driver_side_1').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_2', [1,1], 'traffic_driver_side_2').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_side_3', [1,1], 'traffic_driver_side_3').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/billboard2', [[728,90],[970,90],[970,250]], 'billboard2').defineSizeMapping(billboard).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/billboard3', [[728,90],[970,90],[970,250]], 'billboard3').defineSizeMapping(billboard).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar1', [[300,250],[300,600]], 'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar2', [[300,250],[300,600]], 'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar3', [[300,250],[300,600]], 'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar4', [[300,250],[300,600]], 'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed1', [[300,250],[336,280],[300,600]], 'infeed1').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed2', [[300,250],[336,280],[300,600]], 'infeed2').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed3', [[300,250],[336,280],[300,600]], 'infeed3').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed4', [[300,250],[336,280],[300,600]], 'infeed4').defineSizeMapping(infeed).addService(googletag.pubads())
//         break;

//       case 'Category':
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_ros', [1,1], 'traffic_driver_ros').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar1', [[300,250],[300,600]], 'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar2', [[300,250],[300,600]], 'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar3', [[300,250],[300,600]], 'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar4', [[300,250],[300,600]], 'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed1', [[300,250],[336,280],[300,600]], 'infeed1').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed2', [[300,250],[336,280],[300,600]], 'infeed2').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed3', [[300,250],[336,280],[300,600]], 'infeed3').defineSizeMapping(infeed).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/infeed4', [[300,250],[336,280],[300,600]], 'infeed4').defineSizeMapping(infeed).addService(googletag.pubads())
//         break;

//       case 'Article':
//       case 'sponsored':
//         googletag.defineSlot('/1024786/naftemporiki.gr/traffic_driver_ros', [1,1], 'traffic_driver_ros').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar1', [[300,250],[300,600]], 'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar2', [[300,250],[300,600]], 'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar3', [[300,250],[300,600]], 'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/sidebar4', [[300,250],[300,600]], 'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/article_end', [[300,250],[336,280]], 'article_end').defineSizeMapping(inline).addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_1', [1,1], 'story_tlink_1').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_2', [1,1], 'story_tlink_2').addService(googletag.pubads())
//         googletag.defineSlot('/1024786/naftemporiki.gr/story_tlink_3', [1,1], 'story_tlink_3').addService(googletag.pubads())
//         break;

//       default:
//         break;
//     }
//   })
// }

module.exports = pageSense;