const LOG_PREFIX = '[OCM][User Engagement] ';
const PVC_TIMEOUT = 30 * 60 * 1000

module.exports = class UserEngagement {
    utils
    config
    ue_config
    oid
    oeid
    sent
    ocm_pvc_items
    now
    engaged
    engaged_time
    disengaged_time
    engagement_timer
    totalCompletion
    totalEngagedTime
    hasPageviewCompleteFired
    isLinkClicked
    isFormSubmitted

    constructor(utils, config, oid, oeid) {
        this.utils = utils
        this.config = utils.config
        this.ue_config = this.config.services.user_engagement
        this.oid = oid
        this.oeid = oeid

        this.sent = false

        this.ocm_pvc_items = JSON.parse(this.utils.window.localStorage.getItem('ocm_pvc_items')) || [];

        this.engaged = false
        this.engaged_time = this.getCurrentTime()
        this.disengaged_time = this.engaged_time + 2000
        this.totalCompletion = 0.00;
        this.totalEngagedTime = 0.00;
        this.hasPageviewCompleteFired = false;
        this.isLinkClicked = false;
        this.isFormSubmitted = false;

        this.now = this.getCurrentTime()
    }

    async run() {
        await this.sendAndEmptyEvents()

        await this.createPvcEvent()

        this.initEngagementListener()

        this.updateEventListener()
    }

    async sendAndEmptyEvents() {
        return new Promise(async (resolve, reject) => {
            const tmp_ocm_pvc_items = [...this.ocm_pvc_items]
            if (this.config.debug || this.ue_config.debug) {
                console.log(LOG_PREFIX + 'in sendAndEmptyEvents', this.ocm_pvc_items)
            }

            if (this.utils.window.document.visibilityState === 'visible') {
                // Only the active window is responsible for sending the old events
                for (let i = 0; i < tmp_ocm_pvc_items.length; i++) {
                    let event_key = tmp_ocm_pvc_items[i]
                    const parsedEvent = JSON.parse(this.utils.window.localStorage.getItem(event_key)) || null
                    if (parsedEvent) {
                        if (parsedEvent.tet === 0) {
                            if (this.config.debug || this.ue_config.debug) {
                                console.log(LOG_PREFIX + `event ${event_key} had 0 engagement time, skipping deletion`)
                            }

                            // Delete because of age and inactivity
                            if ((parsedEvent.tl + PVC_TIMEOUT) <= this.now) {
                                if (this.config.debug || this.ue_config.debug) {
                                    console.log(LOG_PREFIX + `event ${event_key} has been here for too long, deleting`)
                                }
                                await this.deletePvcEvent(event_key)
                            }

                            continue
                        }

                        if (this.config.debug || this.ue_config.debug) {
                            console.log(LOG_PREFIX + 'Deleting ' + event_key, parsedEvent)
                        }

                        await this.deletePvcEvent(event_key)
                        await this.sendEventToAPI(parsedEvent)
                            .then(() => {
                                if (this.config.debug || this.ue_config.debug) {
                                    console.log(LOG_PREFIX + 'Successfully sent events to API');
                                }
                            }).catch(() => {
                                if (this.config.debug || this.ue_config.debug) {
                                    console.error(LOG_PREFIX + 'Failed to sent events to API');
                                }
                            })
                    } else {
                        if (this.config.debug || this.ue_config.debug) {
                            console.log(LOG_PREFIX + 'Could not find ' + event_key + ' in localStorage', parsedEvent)
                        }
                    }
                }
            } else {
                if (this.config.debug || this.ue_config.debug) {
                    console.log(LOG_PREFIX + 'Doing nothing, since this is not a visible tab')
                }
            }

            resolve()
        })
    }

    initEngagementListener() {
        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + 'Setting up Engagement')
        }

        let eventsArray = ['touchstart', 'mousemove', 'mousedown', 'scroll', 'keydown', 'resize', 'focus']
        eventsArray.forEach((e => {
            this.utils.window.addEventListener(e, this.utils.throttle(() => {
                // Reset to true if any of thosBe events occur
                this.engaged = true
                this.engaged_time = this.getCurrentTime()

                if (this.config.debug || this.ue_config.debug) {
                    console.log(LOG_PREFIX + 'User engaged')
                }

                // Set a timer to falsify this.engaging
                if (this.engagement_timer) clearTimeout(this.engagement_timer)
                this.engagement_timer = setTimeout(() => {
                    this.engaged = false
                    this.disengaged_time = this.getCurrentTime()
                    this.totalEngagedTime += this.disengaged_time - this.engaged_time

                    if (this.config.debug || this.ue_config.debug) {
                        console.log(LOG_PREFIX + 'Disengaged')
                    }
                }, 2000)
            }, 1000))
        }))
    }

    updateEventListener() {
        setInterval(() => {
            if (this.utils.window.document.visibilityState !== 'visible') {
                return
            }

            // If pvc item does not exist in ocm_pvc_items, include it
            if (!this.ocm_pvc_items.filter(item => item === `ocm_pvc_${this.oeid}`).length) {
                this.ocm_pvc_items.push(`ocm_pvc_${this.oeid}`)
                this.utils.window.localStorage.setItem('ocm_pvc_items', this.ocm_pvc_items)
            }

            this.utils.window.localStorage.setItem(`ocm_pvc_${this.oeid}`, JSON.stringify(this.updatePvcEvent('updateEventListener')))
        }, this.ue_config.interval)
    }

    async createPvcEvent() {
        return new Promise((resolve, reject) => {
            const event = this.updatePvcEvent('createPvcEvent')

            if (this.config.debug || this.ue_config.debug) {
                console.log(LOG_PREFIX + 'Created new PVC event', event)
            }

            this.ocm_pvc_items.push(`ocm_pvc_${this.oeid}`)
            this.utils.window.localStorage.setItem(`ocm_pvc_items`, JSON.stringify(this.ocm_pvc_items))
            this.utils.window.localStorage.setItem(`ocm_pvc_${this.oeid}`, JSON.stringify(event))

            resolve()
        })
    }

    updatePvcEvent(from) {
        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + 'in updatePvcEvent from ' + from)
        }

        const completion = Math.max(this.totalCompletion, this.getCurrentCompletion())
        const currentCompletion = this.getCurrentCompletion();
        this.totalEngagedTime += (this.engaged && from === 'updateEventListener') ? (this.getCurrentTime() - this.engaged_time) : 0
        this.totalCompletion = completion;

        // get the version of OCM.js
        let version = '';

        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + `Build: ${this.config.build}`);
        }

        if (this.config.build) {
            const versionParts = this.config.build.split(".");
            if (versionParts.length > 1) {
                version = versionParts[1];
            }
        }

        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + 'Event `ocm_pvc_' + this.oeid + '` was updated: ', {
                u: this.utils.window.location.href,
                o: this.oeid,
                oi: this.oid,
                d: this.utils.window.location.hostname.replace('www.', ''),
                tet: Math.ceil(parseInt(this.totalEngagedTime) / 1000),
                s: currentCompletion,
                ts: this.totalCompletion,
                tm: this.now,
                tl: this.getCurrentTime(),
                v: version
            });
        }

        return ({
            u: this.utils.window.location.href,
            o: this.oeid,
            oi: this.oid,
            d: this.utils.window.location.hostname.replace('www.', ''),
            tet: Math.ceil(parseInt(this.totalEngagedTime) / 1000),
            s: currentCompletion,
            ts: this.totalCompletion,
            tm: this.now,
            tl: this.getCurrentTime(),
            v: version
        });
    }

    async deletePvcEvent(key) {
        return new Promise((resolve, reject) => {
            if (this.config.debug || this.ue_config.debug) {
                console.log(LOG_PREFIX + 'in deletePvcEvent, key = ' + key)
            }

            this.ocm_pvc_items = JSON.parse(this.utils.window.localStorage.getItem(`ocm_pvc_items`)).filter((item) => item !== key)
            this.utils.window.localStorage.setItem(`ocm_pvc_items`, JSON.stringify(this.ocm_pvc_items))
            this.utils.window.localStorage.removeItem(key)

            resolve()
        })
    }

    async sendEventToAPI(event) {
        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + 'in sendEventToAPI, event = ', event)
            console.log(LOG_PREFIX + 'in sendEventToAPI, tab = ', this.utils.window.location.href)
        }

        if (!event) {
            return
        }

        // get the version of OCM.js
        let version = '';

        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + `Build: ${this.config.build}`);
        }

        if (this.config.build) {
            const versionParts = this.config.build.split(".");
            if (versionParts.length > 1) {
                version = versionParts[1];
            }
        }

        if (this.config.debug || this.ue_config.debug) {
            console.log(LOG_PREFIX + `Version: ${version}`);
        }

        const data = {
            u: event.u,
            t: event.t,
            oi: event.oi,
            tet: parseInt(event.tet),
            s: event.s || 0.00,
            ts: event.ts || 0.00,
            o: event.o,
            h: event.d,
            tm: event.tm,
            v: version
        }

        const urlSearchParams = new URLSearchParams(this.utils.window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let url = 'https://ue.adsquirrel.ai/pvc';

        // let pvcKey = this.utils.window.localStorage.getItem('ocm_pvc_key');
        //
        // if (this.config.debug || this.ue_config.debug) {
        //     console.log(LOG_PREFIX + `Key from params: ${params?.pvcKey}, Key from storage: ${pvcKey}`);
        // }
        //
        // // An uparxei tote
        // if (pvcKey) {
        //     // tsekarw an to redis debug einai false gia na to vgalw
        //     if (params.hasOwnProperty('pvcDebug') && params.pvcDebug === 'false') {
        //         if (this.config.debug || this.ue_config.debug) {
        //             console.log(LOG_PREFIX + ' Removing pvc key from storage');
        //         }
        //         this.utils.window.localStorage.removeItem('ocm_pvc_key');
        //     } else {
        //         url = `https://ue.adsquirrel.ai/pvc-debug?redisKey=${pvcKey}`
        //         if (this.config.debug || this.ue_config.debug) {
        //             console.log(LOG_PREFIX + ' Sending to debug URL');
        //         }
        //     }
        // } else {
        //     if (params.hasOwnProperty('pvcDebug') && params.hasOwnProperty('pvcKey') && params.pvcDebug === 'true') {
        //         if (this.config.debug || this.ue_config.debug) {
        //             console.log(LOG_PREFIX + ' Sending to debug URL and storing the key');
        //         }
        //         this.utils.window.localStorage.setItem('ocm_pvc_key', params.pvcKey);
        //         url = `https://ue.adsquirrel.ai/pvc-debug?redisKey=${params.pvcKey}`;
        //     }
        // }

        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            body: JSON.stringify({
                events: [data]
            }),
            keepAlive: true
        })
    }

    getCurrentCompletion() {
        const w = this.utils.window.document.documentElement.scrollHeight
        const e = this.utils.window.pageYOffset + this.utils.window.innerHeight;
        return e <= 0 ? 0 : e >= w ? 1 : e / w;
    }

    getCurrentTime() {
        let dt = new Date();
        dt.toLocaleString('el-GR', {timeZone: 'Europe/Athens'});
        return dt.getTime();
    }
}
