const LOG_PREFIX = '[OCM][Adlightning] '

let adLightning = (config) => {
    // Create a script element to load ad script
    let adlScript = document.createElement('script');
    // Update src based on link given to you by your PS manager
    if (config.services.adlightning.site && config.services.adlightning.site !== '') {
        adlScript.src = `https://tagan.adlightning.com/orangeclickmedia-${config.services.adlightning.site}/op.js`;
    } else {
        adlScript.src = `https://tagan.adlightning.com/orangeclickmedia/op.js`;
    }

    adlScript.setAttribute('defer', '');
    // Insert script in the head
    document.head.appendChild(adlScript);

    if (config.debug || config.services.adlightning.debug) {
        console.info(LOG_PREFIX + 'Loaded')
    }
};

module.exports = adLightning;
