const LOG_PREFIX = '[OCM][Fizz] '

module.exports = class Fizz {
    utils
    config
    fizz_config
    slots = []
    lazyload
    hb_functionality
    engaging = true
    engagement_timer = null

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.fizz_config = config.services.fizz
        this.lazyload = config.services.lazyload
        this.hb_functionality = (config.services.hasOwnProperty('header_bidding') && config.services.header_bidding.active) ?
            config.services.header_bidding.functionality : null;
    }

    run() {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        this.initEngagementListener()
        this.initGoogleTagListener()
    }

    isInViewport(elem_id) {
        let bounding
        let element = document.getElementById(elem_id)
        let isSkyscraper = false
        let percentVisible = 0

        if (!element || typeof element === 'undefined') {
            return false
        }

        if (element.style.display === 'none') {
            element.style.display = 'block'
            bounding = element.getBoundingClientRect()
            element.style.display = 'none'
        } else {
            bounding = element.getBoundingClientRect()
        }

        isSkyscraper = (bounding.height >= 600)

        // Check if it's 100% in viewport
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (this.utils.window.innerHeight || this.utils.window.document.documentElement.clientHeight) &&
            bounding.right <= (this.utils.window.innerWidth || this.utils.window.document.documentElement.clientWidth)
        ) {
            return true
        }

        // Check if some of it is not visible (from top)
        if (
            bounding.top < 0 &&
            bounding.left >= 0
        ) {
            percentVisible = ((bounding.height - Math.abs(bounding.top)) / bounding.height) * 100
            if (isSkyscraper) {
                if (percentVisible >= 67) {
                    return true
                }
            } else {
                if (percentVisible >= 51) {
                    return true
                }
            }
        }

        // Check if some of it is not visible (from bottom)
        if (
            bounding.bottom >= (this.utils.window.innerHeight || document.documentElement.clientHeight) &&
            bounding.left >= 0
        ) {
            percentVisible = ((bounding.height - (bounding.bottom - (this.utils.window.innerHeight || this.utils.window.document.documentElement.clientHeight))) / bounding.height) * 100
            if (isSkyscraper) {
                if (percentVisible >= 67) {
                    return true
                }
            } else {
                if (percentVisible >= 51) {
                    return true
                }
            }
        }

        return false
    }

    allowByAdvertiser(advertiser_id) {
        let allow = false
        let advertisers_length = this.fizz_config.advertisers_whitelist.length

        if (advertisers_length) {
            for (let a = 0; a < advertisers_length; a++) {
                if (this.fizz_config.advertisers_whitelist[a].id === advertiser_id) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Found whitelisted advertiser ' + this.fizz_config.advertisers_whitelist[a].name)
                    }

                    allow = true

                    break
                }
            }
        } else {
            allow = true
        }

        return allow
    }

    allowByAdUnit(adunit_path) {
        let allow = false
        let filter

        let ads_length = this.fizz_config.ads_whitelist.length

        if (ads_length) {
            filter = this.fizz_config.ads_whitelist.filter((ad) => {
                return (ad.path === adunit_path);
            })

            if (filter.length) {
                allow = true
            }
        }

        return allow
    }

    determineHbAdUnits(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'Determining HB ad units...')
        }

        let refresh_hb_ads = []

        for(const bubble of bubbles) {
            for(let adunit of this.utils.window.OCM.ad_units) {
                if (adunit.code === bubble.code) {
                    refresh_hb_ads.push({
                        code: adunit.code,
                        mediaTypes: adunit.mediaTypes,
                        bids: adunit.bids
                    })
                }
            }
        }

        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'Determined HB ad units', refresh_hb_ads)
        }

        return refresh_hb_ads
    }

    refreshDfp(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'in refreshDfp')
        }

        let slots = []
        Object.keys(bubbles).forEach((bubble) => {
            this.utils.window.googletag.cmd.push(() => {
                bubbles[bubble].gptslot.setTargeting('ocmFizz', ['true'])
            })
            slots.push(bubbles[bubble].gptslot)
        })

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().refresh(slots)
        })
    }

    refreshHb(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'in refreshHb')
        }

        let refresh_hb_ads = this.determineHbAdUnits(bubbles)

        if (refresh_hb_ads.length) {
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.removeAdUnits()

                this.utils.window.ocmpbjs.addAdUnits(this.utils.window.OCM.ad_units)

                this.utils.window.ocmpbjs.requestBids({
                    bidsBackHandler: (bidResponses) => {
                        for (let divId in bidResponses) {
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + 'Found bid for #' + divId)
                            }

                            let ad_div = document.getElementById(divId)

                            if (ad_div) {

                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX + 'Ad div = ', ad_div)
                                }

                                let frmEl = document.createElement('iframe')
                                frmEl.setAttribute('id', divId + '_ocm_hb')
                                frmEl.style.border = '0'
                                frmEl.style.overflow = 'hidden'
                                frmEl.style.margin = '0'
                                frmEl.TOPMARGIN = '0'
                                frmEl.LEFTMARGIN = '0'
                                frmEl.ALLOWTRANSPARENCY = 'true'
                                frmEl.width = '0'
                                frmEl.height = '0'

                                ad_div.appendChild(frmEl)

                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX + ': Appended frmEl to ad_div', ad_div)
                                }

                                let iframeDoc = frmEl.contentWindow.document
                                let adServerTargeting = this.utils.window.ocmpbjs.getAdserverTargetingForAdUnitCode(divId)
                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX + ': adServerTargeting', adServerTargeting)
                                }

                                // If any bidders return any creatives
                                if (adServerTargeting && adServerTargeting['hb_adid']) {
                                    if (this.config.debug || this.fizz_config.debug) {
                                        console.log(LOG_PREFIX + ': Rendering ad for ' + divId)
                                    }
                                    this.utils.window.ocmpbjs.renderAd(iframeDoc, adServerTargeting['hb_adid'])
                                    ad_div.querySelector('script').remove()
                                    ad_div.querySelector('div[id^="google_ads_iframe_"]').remove()
                                    ad_div.style.display = 'block'
                                }
                            } else {
                                if (this.config.debug || this.fizz_config.debug) {
                                    console.warn(LOG_PREFIX + ': Ad div #' + divId + ' not found')
                                }
                            }
                        }
                    }
                })
            })
        }
    }

    refreshDfpHb(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'in refreshHbDfp')
        }

        this.utils.window.ocmpbjs.adUnits = []

        let refresh_hb_ads = this.determineHbAdUnits(bubbles)

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.addAdUnits(refresh_hb_ads)
                this.utils.window.ocmpbjs.requestBids({
                    adUnitCodes: refresh_hb_ads.map(function (unit) {
                        return unit.code
                    }),
                    bidsBackHandler: () => {
                        let slots = []
                        try {
                            Object.keys(bubbles).forEach((bubble) => {
                                this.utils.window.googletag.cmd.push(() => {
                                    bubbles[bubble].gptslot.setTargeting('ocmFizz', ['true'])
                                })

                                // TODO this will have to play along with the viewability service
                                // if (this.config.services.hasOwnProperty('viewability') && this.config.services.viewability.active) {
                                //     var viewability = null
                                //     if (viewability = ocmViewabilityPrediction(bubbles[bubble].gptslot)) {
                                //         console.log('setTargeting', viewability)
                                //         bubbles[bubble].gptslot.setTargeting('ocmViewability', [viewability])
                                //     }
                                // }

                                slots.push(bubbles[bubble].gptslot)
                            })
                        } catch (e) {
                            console.error('OCM Fizz: ', e)
                        }

                        if (slots.length) {
                            this.utils.window.ocmpbjs.setTargetingForGPTAsync(slots.map(function (slot) {
                                return slot.getAdUnitPath()
                            }))
                            this.utils.window.googletag.pubads().refresh(slots)
                            this.utils.window.ocmpbjs.initAdserverSet = true
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + '=> HB: Called initAdServer')
                            }
                        }
                    }
                })
            })
        })
    }

    refreshDfpHbLazyload(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'in refreshDfpHbLazyload')
        }

        for(const bubble of bubbles) {
            let element = document.querySelector('div[data-oau-code="' + bubble.code + '"]')
            if (element && !element.hasAttribute('data-lazyincluded-by-ocm')) {
                // This should trigger the lazyload combust
                element.removeAttribute('data-lazyloaded-by-ocm')
                element.removeAttribute('data-oau-code')
                this.utils.window.googletag.cmd.push(() => {
                    bubble.gptslot.setTargeting('ocmFizz', ['true'])
                })
            }
        }
    }

    initEngagementListener() {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'Setting up Engagement')
        }

        ['touchstart', 'touchmove', 'click', 'scroll', 'keyup'].forEach((e) => {
            this.utils.window.addEventListener(e,this.utils.throttle(() => {
                // Reset to true if any of those events occur
                this.engaging = true
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'User engaged')
                }
                // Set a timer to falsify this.engaging
                if (this.engagement_timer) clearTimeout(this.engagement_timer)
                this.engagement_timer = setTimeout(() => {
                    this.engaging = false
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Disengaged')
                    }
                }, 10000)
            }, 1000))
        })
    }

    initGoogleTagListener() {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + 'Setting up GPT Listener...')
        }

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                if (!this.interval_started) {
                    this.startInterval()
                }

                let slotAdUnitPath = event.slot.getAdUnitPath()
                let slotElementId = event.slot.getSlotElementId()
                let slotResponse = (!event.isEmpty) ? event.slot.getResponseInformation() : null

                // Never refresh not allowed ad unit codes
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'Checking if ad unit "' + slotAdUnitPath + '" is in whitelisted ad units ', this.fizz_config.ads_whitelist)
                }

                if (!this.allowByAdUnit(slotAdUnitPath)) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Blocking refresh of ' + slotAdUnitPath + ' due to not whitelisted ad unit path')
                    }
                    return
                }

                if (!event.isEmpty) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Checking ' + slotAdUnitPath + ' for whitelisted advertiser id = ' + slotResponse.advertiserId)
                    }
                    if (!this.allowByAdvertiser(slotResponse.advertiserId)) {
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + 'Blocking refresh of ' + slotAdUnitPath + ' due to not whitelisted advertiser id = ' + slotResponse.advertiserId)
                        }

                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + 'Removing ' + slotAdUnitPath + ' from fizz slots due to not allowed by advertiser')
                        }
                        this.removeFromSlots(slotElementId, slotAdUnitPath, slotResponse)

                        return
                    }
                }

                // Create new slots entry in fizz
                if (!this.slots.hasOwnProperty(slotElementId)) {
                    this.slots[slotElementId] = {
                        "gptslot": event.slot,
                        "div": slotElementId,
                        "code": slotAdUnitPath,
                        "rendered_at": Date.now(),
                        "is_empty": event.isEmpty,
                        "viewed_at": null
                    }

                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Created new slot for ' + slotElementId, this.slots[slotElementId])
                    }
                } else {
                    // Update time entry for existing slot in fizz
                    this.slots[slotElementId].rendered_at = Date.now()

                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Updated time for slot ' + slotElementId, this.slots[slotElementId])
                    }
                }

                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'All fizz slots', this.slots)
                }
            })

            this.utils.window.googletag.pubads().addEventListener('impressionViewable', (event) => {
                let slotElementId = event.slot.getSlotElementId()
                // Added time entry for existing slot in fizz
                if (typeof this.slots[slotElementId] !== "undefined") {
                    this.slots[slotElementId].viewed_at = Date.now();

                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Viewability triggered time update for slot ' + slotElementId, this.slots[slotElementId]);
                    }
                }
            })
        })
    }

    removeFromSlots(slotElementId, slotAdUnitPath, slotResponse) {
        if (this.slots.hasOwnProperty(slotElementId)) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + 'Removing ad slot ' + slotAdUnitPath + ' due to not whitelisted advertiser id = ' + slotResponse.advertiserId)
            }

            this.slots.splice(this.slots.findIndex(slotElementId), 1)
        }
    }

    startInterval() {
        this.interval_started = true

        setInterval(() => {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + 'Running interval')
            }

            let bubbles = []
            // Check for engagement eligibility
            if (this.engaging) {
                let now = Date.now()
                Object.keys(this.slots).forEach((key) => {
                    let slot = this.slots[key]
                    // Check for time passed individually per slot
                    // Check for time passed
                    let time_since_render = now - slot.rendered_at;
                    let time_since_viewed = (slot.viewed_at) ? (now - slot.viewed_at) : 0;

                    if (time_since_render >= this.fizz_config.interval && (time_since_viewed >= 3000 || slot.is_empty)) {
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + slot.div + ' passed time requirements')
                        }
                        // Check for viewability
                        if (this.isInViewport(slot.div)) {
                            bubbles.push(slot)
                        } else {
                            if (this.config.debug || this.fizz_config.debug) {
                                console.warn(LOG_PREFIX + slot.div + ' not in viewport')
                            }
                        }
                    }
                })
            } else {
                if (this.config.debug || this.fizz_config.debug) {
                    console.warn(LOG_PREFIX + 'User disengaged')
                }
            }

            if (bubbles.length) {
                // Refresh all those nice bubbles :D
                switch (this.hb_functionality) {
                    case null: // HB is inactive
                        this.refreshDfp(bubbles)
                        break
                    case 'no_adserver':
                        this.refreshHb(bubbles)
                        break
                    case 'lazyload_v1':
                    case 'lazyload_v2':
                        this.refreshDfpHbLazyload(bubbles)
                        break;
                    default: //adserver
                        this.refreshDfpHb(bubbles)
                        break
                }
            } else {
                if (this.config.debug || this.fizz_config.debug) {
                    console.warn(LOG_PREFIX + 'No bubble ads found')
                }
            }

        }, 3000)
    }
}
