const GapiAuth = require('./gapi_auth')
const ga = new GapiAuth()

module.exports = class Log {
    jwt
    build
    hostname
    url

    constructor(build, hostname, url) {
        this.build = build
        this.hostname = hostname
        this.url = url
        this.jwt = null
    }

    stringifyError(error) {
        if (this.hasJsonStructure(error)) {
            return JSON.stringify(error)
        }

        return error
    }

    hasJsonStructure(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

    error(obj) {
        this.send('ERROR', obj)
    }

    warning(obj) {
        this.send('WARNING', obj)
    }

    notice(obj) {
        this.send('NOTICE', obj)
    }

    info(obj) {
        this.send('INFO', obj)
    }

    debug(obj) {
        this.send('DEBUG', obj)
    }

    async send(severity, obj) {
        if (!this.jwt) {
            this.jwt = await ga.run()
        }

        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.jwt}`,
                'Accept': 'application/json; charset=UTF-8',
                'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                logName: 'projects/ocm-logs/logs/ocm-js',
                resource: {
                    type: 'global',
                    labels: {
                        project_id: 'ocm-logs',
                        product: 'ocm.js'
                    }
                },
                entries: [
                    {
                        jsonPayload: {
                            hostname: this.hostname,
                            url: this.url,
                            build: this.build.split('.')[1],
                            userAgent: window.navigator.userAgent,
                            service: obj.service,
                            msg: obj.msg,
                            error: this.stringifyError(obj.error),
                            consent: window?.OCM?.hasPurposeOneConsent,
                            adBlocked: window?.OCM?.adBlocked
                        },
                        severity: severity
                    }
                ]
            })
        }

        return await fetch('https://logging.googleapis.com/v2/entries:write', options)
    }
}
