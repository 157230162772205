const LOG_PREFIX = '[OCM][AniView] '

module.exports = class AniView {
    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.aniview_config = (this.utils.is_mobile) ? config.services.aniview.mobile : config.services.aniview.desktop
    }

    run() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (this.utils.allowPageType(this.aniview_config.rules.page_types)) {
            this.createPlayerElement()
        } else if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + "Page type doesn't match the selected ones: ", this.aniview_config.rules.page_types)
        }
    }


    createPlayerElement() {
        this.utils.contentLoaded().then(() => {
            this.inject()
        })
    }

    inject() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Trying to inject player element')
        }

        let node = this.utils.determineInjectionTarget(this.aniview_config.rules.selector, this.aniview_config.rules.position, this.aniview_config.rules.count_gt, this.aniview_config.rules.word_count, this.aniview_config.rules.words_gt)

        if (node) {
            let s = document.createElement('script')
            s.id = `AV${this.aniview_config.playerTagId}`
            s.type = "text/javascript"
            s.src = `https://tg1.aniview.com/api/adserver/spt?AV_PUBLISHERID=${this.aniview_config.publisher}&AV_TAGID=${this.aniview_config.playerTagId}`
            s.async = "true"
            s.setAttribute('data-player-api', 'avPassback')

            let ocmPlayer = this.utils.window.document.createElement('div')
            ocmPlayer.classList.add('ocm-player')
            if (this.aniview_config.classes) {
                ocmPlayer.classList.add(this.aniview_config.classes)
            }
            if (this.aniview_config.styles) {
                ocmPlayer.style = this.aniview_config.styles
            }

            this.utils.injectTag(node, ocmPlayer, this.aniview_config.rules.place)
            ocmPlayer.append(s)

            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'Selector found and script added')
            }

            this.createPassback()
        } else {
            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'No selector found')
            }
        }

    }

    createPassback() {
        this.utils.window.avPassback = (config, player) => {
            player.on('AdError', (event, eventData) => {
                if (typeof window.OCM.oipb == 'function') {
                    window.OCM.oipb(event, eventData)
                }
            })
        }
    }
}
