const LOG_PREFIX = "[OCM][Custom] "
const Fizz = require('../../services/fizz')
// const Instream = require('../../services/instream')

let customCode = (utils) => {
  return new Promise((resolve, reject) => {
    utils.contentLoaded().then(() => {
      if (window.OCM.pageType !== 'sponsored' && window.OCM.pageType !== 'blocked') {
      	runOutstream(utils)
      }
    })
    
    if (window.location.href.includes('finance/athexRealTimeTicker')) {
      utils.config.services.fizz.active = true
      const fizz = new Fizz(utils, utils.config)
      fizz.run()
    }
    
    resolve(true)
  });
};

let runOutstream = (utils) => {
  (function (w, d, s) {
    try {
      d = w.top.document || d;
      w = w.top.document ? w.top : w;
    } catch (e) {}
    var ttag = function () {
      w.teads
        .page(140373)
        .placement(154082, {
        slider: { allow_corner_position: false, allow_top_position: false },
        css: "margin: 0px 0px 26px;",
        format: "inread",
        slot: {
          insertAfter: true,
          btf: false,
          selector: "#storyBody > p, #spBody > p",
          minimum: 4,
        },
      })
        .passback(function () {
        ocmPassback(utils, document.querySelectorAll("#storyBody > p, #spBody > p")[3]);
        
//         window.OCM.oipb = () => {
//           if (utils.is_mobile) {
            
//           }
//         }
        
//         utils.config.services.instream.active = true
//         const is = new Instream(utils, utils.config)
//         is.run()
      })
        .serve();
    };
    if (w.teads && w.teads.page) {
      ttag();
    } else if (!w.teadsscript) {
      s.src = "https://s8t.teads.tv/media/format/v3/teads-format.min.js";
      s.async = true;
      s.onload = ttag;
      w.teadsscript = d.getElementsByTagName("head")[0].appendChild(s);
    }
  })(window, document, document.createElement("script"));
}

let ocmPassback = (utils, selector) => {
  var googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(function () {
    if (selector) {
      googletag.defineSlot("/1024786/naftemporiki.gr/inread_pb", [[1, 1], [300,250], [336,280]],"ocm-outstream").addService(googletag.pubads());

      var outstream_div = document.createElement("div");
      outstream_div.id = "ocm-outstream";
      outstream_div.style.marginBottom = "20px";
      var outstream_script = document.createElement("script");
      outstream_script.text ='googletag.cmd.push(function() { googletag.display("ocm-outstream"); })';
      selector.parentNode.insertBefore(outstream_div, selector.nextElementSibling);
      outstream_div.append(outstream_script);
    }
  });
}

module.exports = customCode;