/* This script auto-generates a Google OAuth token from a Service Account key,
 * and stores that token in accessToken variable in Postman.
 *
 * Prior to invoking it, please paste the contents of the key JSON
 * into serviceAccountKey variable in a Postman environment.
 *
 * Then, paste the script into the "Pre-request Script" section
 * of a Postman request or collection.
 *
 * The script will cache and reuse the token until it's within
 * a margin of expiration defined in EXPIRES_MARGIN.
 *
 * Thanks to:
 * https://paw.cloud/docs/examples/google-service-apis
 * https://developers.google.com/identity/protocols/OAuth2ServiceAccount#authorizingrequests
 * https://gist.github.com/madebysid/b57985b0649d3407a7aa9de1bd327990
 * https://github.com/postmanlabs/postman-app-support/issues/1607#issuecomment-401611119
 */

const rs = require('jsrsasign');
const JWS = rs.jws.JWS;

module.exports = class GapiAuth {

    constructor() {
        this.accessToken = null
        this.serviceAccountKey = {
            // type: "service_account",
            // project_id: "ocm-logs",
            // private_key_id: "ed16bf33829ddd819ac695155411ebc2f5e83631",
            private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCziXpsfM5psnbj\nIwe7VwKixx9wRGPPl6FfGQyP+Oa45XCu/iPsMHNxGn0zAlhZAViguAkM6SxTdYyx\nNHsltAZPtug4A7qE3ZBZew+ZbMYZZjQ/koU6tSX+YRmTpqr+bFWc+nX90/9XezfR\nt1XX7DPpN8almWQOXnHB0ZeiDJ1kWbD3ZfAamAnE6Ry9ESPo/Pe5FnjMs2LYHSy/\nf75VmIuCuxjz/WVmeBTNbgmetugvymdvavLrxSZ6hdB6Ewd0dMW0Zh3qjLArb/XR\nDAICntfO33+/r/q751jtiBzh04o2zQQmGDJh7DbFKUDvnevkkI+/6zkSc+ff/QR7\nuXcVjJaLAgMBAAECggEAAL50Ydgh+2gk6bBTVE4etj9lRc/3Tw8NptkWcGG4Fncy\npQ3ZZtRwkXPX7vibX6+d4ot3irSVON4rsF1wBcWLobilT7KuiwoeSXCi9qA46DZS\noxqEL6yFWaQSyzOyCEVLgqsovbk9mOsP8AJkVaxLCjQ4iTkiyJPjU1wjsgCjqlN/\nCWNyZ+2f5fgrlAh7a5Bg/LtHzWnp/wjQ2U1Kp1mUgLZ7EfV2JG22kE3zQggKynIW\nohKOckJpMINbObp4idD8Op0/gbIobreoSimAKWxto/AHOykqiyhsUsx81S7I8mXq\nEXn5a+MLfEh8o9hAr5UY/4mm7AH1LgX45Oaz9jkkyQKBgQDqnQVFFaT50KJZxCkO\niUlwBqbvA5FSuT8n12/5drYZ7zFpa+EglZ4GzfU5aGCp67bXoKbpjR05tTAYAL6p\nE1PFlqnfLXtv/KQlZ5uPY+Oanahg+TMHX1b1gHwi2uTFj6yD3hYl5nlTOd12t6N1\n+aTO1K1KAg/wRhuqg8ipHfQJFQKBgQDD5y+1cGJTRBGXSJJpoZuUHeqGCLF/8R+T\nrRSGL1Q9lqaS+N0D4DSOuNOWMENsRA48TDr/ChXthAvEHChZzKMuAsGnBdV4/qK7\n+mceqPLLulkqlRhdTrmL4snMjSaqwlNehAdloJS+M6eR4pEOPuMgVjoiSd8lAFo5\nf6jjLebJHwKBgDTiok55W4/ohMBBZJPfRIuZJEYagsan9WGQwa/1H4VnErdV1xCW\nSWH1SULgkXGcLcIbd6SL57ubaTTv7gqEzcxrcu1Y/ks3IvU/jqRa+vJeeZcqdWND\nDYIXrWPZ/dm+0j+Db3CprE2jbJWXPaSZWpo4/LsDDf62fwQH4tKkat8lAoGActL1\n3sYxi5aRLPgHgUyNESL7zHYZ++DL3lYLep8RJExB9FFT0iq/A4yH0DcXvcSiB2uz\nBLvXoHDOKLNMX14XoydQdGJx/kpZ/g5GFO136jueedwL/Nzg2oIiP/fX2yytiu+e\nGk4mANnr9RE0boIjxFdmGaTs2xY6zArsZcQIyocCgYAM055vrwU40SCvIFgemstr\n9ilUiNSrKW+Y/vujGRtQp9/D1QwU2n6iNsLo/gs85GzH1STsagRbsLny504oTpfs\nOvc4lNek5JiL9/JFR07pH7/F9+STOgJgcn7oUnlfd/t86Th59Gx7H0v+RkhIXbtH\nFMMKkjRL/CjPolat6n41rw==\n-----END PRIVATE KEY-----\n",
            client_email: "ocm-logger@ocm-logs.iam.gserviceaccount.com",
            // client_id: "100535031374582851015",
            // auth_uri: "https://accounts.google.com/o/oauth2/auth",
            // token_uri: "https://oauth2.googleapis.com/token",
            // auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
            // client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/ocm-logger%40ocm-logs.iam.gserviceaccount.com"
        };
        this.jwt = ''
        this.googleAuthURL = 'https://oauth2.googleapis.com/token';
        // add/remove your own scopes as needed
        this.SCOPES = [
            'https://www.googleapis.com/auth/logging.write',
        ];

        this.EXPIRES_MARGIN = 1800; // seconds before expiration
    }

    run() {
        return new Promise((resolve, reject) => {
            const now = Math.floor(Date.now() / 1000)
            this.getJwt(this.serviceAccountKey, now)

            const init = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                    assertion: this.jwt,
                }),
            };

            fetch(this.googleAuthURL, init)
                .then(response => response.json())
                .then(data => {
                    this.accessToken = data.access_token;
                    resolve(this.accessToken)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    getJwt({client_email, private_key}, iat) {
        const header = {
            typ: 'JWT',
            alg: 'RS256',
        };

        const exp = iat + 3600;
        const payload = {
            aud: this.googleAuthURL,
            iss: client_email,
            scope: this.SCOPES.join(' '),
            iat,
            exp,
        };

        this.jwt = JWS.sign(header.alg, header, payload, private_key);
    }
}
