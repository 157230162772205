const LOG_PREFIX = '[OCM][Primis] '

module.exports = class Primis {
    utils
    config
    primis_config
    conditions

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.primis_config = (this.utils.is_mobile) ? this.config.services.primis.mobile : this.config.services.primis.desktop
    }

    run() {
        if (this.config.debug || this.primis_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        let node = this.utils.determineInjectionTarget(this.primis_config.conditions.selector, this.primis_config.conditions.position, this.primis_config.conditions.count_gt, this.primis_config.conditions.word_count, this.primis_config.conditions.words_gt)

        if (!node) {
            if (this.config.debug || this.primis_config.debug) {
                console.log(LOG_PREFIX + 'Could not find selector ' + this.primis_config.conditions.selector)
            }
            return;
        }

        this.create(node)
    }

    create(target) {
        if (this.primis_config.conditions.geo_filtering) {
            this.utils.geolocation().then((country) => {
                if (!this.primis_config.conditions.geo_filtering_countries.includes(country)) {
                    if (this.config.debug || this.primis_config.debug) {
                        console.log(LOG_PREFIX + 'Terminating due to geo filter', this.primis_config.conditions.geo_filtering_countries, country)
                    }
                    return
                }
            })
        }

        let schain = typeof this.config.schain !== 'undefined' ? this.config.schain.config.nodes[0]['sid'] : ''

        let div = document.createElement('div');
        div.setAttribute('style', this.config.services.primis.style)
        if (this.utils.injectTag(target, div, this.primis_config.conditions.place)) {
            let script = document.createElement('script');
            script.async = true;
            script.src = this.config.services.primis.src + "&schain=1.0,1!orangeclickmedia.com," + schain + ",1";
            div.appendChild(script);
        }
    }
}
