const LOG_PREFIX = '[OCM][Instream] '
const IS_CDN_PATH = '//cdn.orangeclickmedia.com/tech/libs/ocm-ois.js'

module.exports = class Instream {
    utils;
    config;
    is_config;
    conditions;
    stickyConfig;
    selector;

    constructor(utils, config) {
        this.utils = utils;
        this.config = config;
        this.is_config = config.services.instream;
        this.conditions = (utils.is_mobile) ? this.is_config.mobile.conditions : this.is_config.desktop.conditions;
        this.stickyConfig = (utils.is_mobile) ? this.is_config.mobile.sticky : this.is_config.desktop.sticky;
        this.selector = null;
    }

    run() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Running...');
        }

        if (!this.utils.allowPageType(this.conditions.page_types)) {
            if (this.config.debug || this.is_config.debug) {
                console.log(LOG_PREFIX + 'Page type not allowed, terminating process',  this.conditions.page_types);
            }

            return;
        }

        this.loadIma().then(() => {
            const wrapper = this.createWrapper();

            const node = this.utils.determineInjectionTarget(
                this.conditions.selector,
                this.conditions.position,
                this.conditions.count_gt,
                this.conditions.words,
                this.conditions.words_gt,
            )

            if (!node) {
                if (this.config.debug || this.is_config.debug) {
                    console.info(LOG_PREFIX + 'selector NOT found, stopping process', node);
                }

                return;
            }

            if (wrapper) {
                this.utils.injectTag(node, wrapper, this.conditions.place);

                IntersectionObserver.prototype.POLL_INTERVAL = 100;
                let observer_config = {
                    root: null,
                    rootMargin: '100px 0px'
                };
                const observer = new IntersectionObserver((entries, self) => {
                    entries.forEach((entry) => {
                        if (typeof entry.isVisible === 'undefined') {
                            entry.isVisible = true
                        }

                        if (entry.isIntersecting) {
                            this.populateWrapper(entry.target);
                            self.unobserve(entry.target);
                        }
                    })
                }, observer_config)

                observer.observe(wrapper);
            }

            this.setGlobals();
        })
    }

    createWrapper() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Creating wrapper div#ocm-ois');
        }

        this.utils.loadStyle('#ocm-ois { max-width:640px; display:block; margin:0 auto 1rem; position: relative; }');

        let wrapper = this.utils.window.document.createElement('div');
        wrapper.id = 'ocm-ois';

        const styles = (this.utils.is_mobile) ? this.is_config.mobile.styles : this.is_config.desktop.styles;
        const classLists = (this.utils.is_mobile) ? this.is_config.mobile.classes : this.is_config.desktop.classes;

        if (styles && styles !== '') {
            wrapper.style = styles;
        }

        if (classLists && classLists !== '') {
            wrapper.setAttribute('class', classLists);
        }

        return wrapper;
    }

    populateWrapper(wrapper) {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Populating #ocm-ois')
        }

        const script = this.utils.window.document.createElement('script')
        script.src = IS_CDN_PATH
        wrapper.append(script)
    }

    loadIma() {
        return new Promise((resolve, reject) => {
            try {
                const scripts = this.utils.window.document.getElementsByTagName('script')[0]
                const s = this.utils.window.document.createElement('script')
                s.src = "//imasdk.googleapis.com/js/sdkloader/ima3.js"
                scripts.parentNode.insertBefore(s, scripts)
                s.onload = () => {
                    resolve()
                }
            } catch (e) {
                reject()
            }
        })
    }

    setGlobals() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Setting globals');
        }

        this.utils.window.OCM.IS = {
            debug: (this.config.debug || this.is_config.debug),
            ad_tag_url: this.is_config.ad_tag_url,
            video_url: this.is_config.video_url,
            color: this.is_config.color,
            sticky: this.stickyConfig,
            dispose_after_ad: this.is_config.dispose_after_ad,
            device: this.utils.is_mobile ? 'mobile' : 'desktop'
        }
    }
}
