const LOG_PREFIX = '[OCM][InstreamHB] '

module.exports = class InstreamHB {
    adunits
    utils
    config
    is_config
    videoGranularity
    oldGranularity
    conditions
    stickyConfig

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.is_config = config.services.instream_hb
        this.conditions = (utils.is_mobile) ? this.is_config.mobile.conditions : this.is_config.desktop.conditions
        this.stickyConfig = (utils.is_mobile) ? this.is_config.mobile.sticky : this.is_config.desktop.sticky
        this.adunits = []

        this.videoGranularity = {
            "buckets": [
                {
                    "precision": 2,
                    "min": 0.01,
                    "max": 9,
                    "increment": 0.01
                },
                {
                    "precision": 2,
                    "max": 54,
                    "increment": 0.10
                },
            ]
        }
    }

    run() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (!this.utils.allowPageType(this.conditions.page_types)) {
            if (this.config.debug || this.is_config.debug) {
                console.log(LOG_PREFIX + 'Terminating due to page type')
            }
            return
        }

        this.setGlobals();
        const wrapper = this.injectWrapper();

        this.init();

        IntersectionObserver.prototype.POLL_INTERVAL = 100;
        let observer_config = {
            root: null,
            rootMargin: '100px 0px'
        };
        const observer = new IntersectionObserver((entries, self) => {
            entries.forEach((entry) => {
                if (typeof entry.isVisible === 'undefined') {
                    entry.isVisible = true
                }

                if (entry.isIntersecting) {
                    this.loadIma().then(() => {
                        this.auction();
                    })
                    self.unobserve(entry.target);
                }
            })
        }, observer_config)

        observer.observe(wrapper);
    }

    init() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Loading ocmpb_instream lib...')
        }

        // Load ocmpb_instream.js library
        this.utils.loadScript('https://cdn.orangeclickmedia.com/tech/libs/ocmpb_instream.js', 'text/javascript', 1)

        this.prebidConfig()

        // Build the ad units configuration
        this.buildAdUnits()
    }

    buildAdUnits() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Building ad units...', this.is_config.adunits)
        }
        for (const adunit of this.is_config.adunits) {
            this.adunits.push({
                code: adunit.code,
                mediaTypes: {
                    video: {
                        context: 'instream',
                        playerSize: [[640, 360]],
                        mimes: ['video/mp4', 'video/x-ms-wmv', 'video/3gpp', 'video/ogg', 'video/x-msvideo', 'video/mpeg', 'video/mpg', 'video/webm', 'video/m2t', 'video/3gpp2'],
                        protocols: [1, 2, 3, 4, 5, 6, 7, 8],
                        playbackmethod: [1, 2, 5, 6],
                        linearity: 1,
                        startdelay: 0,
                        skip: 1
                    },
                },
                bids: adunit.bids
            })
        }
    }

    auction() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Running HB auction...')
        }

        this.utils.window.ocmispbjs.que.push(() => {
            this.utils.window.ocmispbjs.addAdUnits(this.adunits);

            this.utils.window.ocmispbjs.requestBids({
                bidsBackHandler: (adunits, timedOut, auctionId) => {
                    // if (adunits && Object.keys(adunits).length === 0) {
                    //     if (typeof this.utils.window.OCM.oipb === 'function') {
                    //         if (this.config.debug || this.is_config.debug) {
                    //             console.log(LOG_PREFIX + 'No bids came back, running instreamHB pb')
                    //         }
                    //         this.utils.window.OCM.oipb()
                    //     }
                    //     return;
                    // }

                    this.adunits.forEach((adunit) =>  {
                        let adTagUrl = this.utils.window.ocmispbjs.adServers.dfp.buildVideoUrl({
                            adUnit: adunit.code,
                            params: {
                                iu: adunit.code,
                                sz: '1x1|300x169|400x300|640x360|640x480|854x480|1280x720|1920x1080',
                                vad_type: 'linear',
                                unviewed_position_start: 1,
                                env: 'vp',
                                output: 'vast',
                            }
                        })

                        // Mark the bid, used in buildVideoUrl, as used
                        // this.utils.window.ocmispbjs.markWinningBidAsUsed({
                        //     adId: bid.adId
                        // });

                        this.utils.window.OCM.ISHB.vast_tag_urls.push(adTagUrl);
                    })

                    if (this.utils.window.OCM.ISHB.vast_tag_urls.length) {
                        if (this.config.debug || this.is_config.debug) {
                            console.log(LOG_PREFIX + 'Vast tags:', this.utils.window.OCM.ISHB.vast_tag_urls)
                            console.log(LOG_PREFIX + 'invoking video player')
                        }
                        this.invokeVideoPlayer()
                    } else {
                        if (typeof this.utils.window.OCM.oipb === 'function') {
                            if (this.config.debug || this.is_config.debug) {
                                console.log(LOG_PREFIX + 'Empty vast tags, calling instreamHB pb')
                            }
                            this.utils.window.OCM.oipb()
                        }
                    }
                }
            });
        })
    }

    injectWrapper() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Injecting #ocm-ois')
        }

        this.utils.loadStyle('#ocm-ois { max-width:640px; display:block; margin:0 auto 1rem; position: relative; }');

        let target = this.utils.determineInjectionTarget(this.conditions.selector, this.conditions.position, this.conditions.count_gt, this.conditions.words, this.conditions.words_gt)
        let node = this.utils.window.document.createElement('div')
        node.id = 'ocm-ois'

        const styles = (this.utils.is_mobile) ? this.is_config.mobile.styles : this.is_config.desktop.styles;
        const classLists = (this.utils.is_mobile) ? this.is_config.mobile.classes : this.is_config.desktop.classes;

        if (styles && styles !== '') {
            node.style = styles;
        }

        if (classLists && classLists !== '') {
            node.setAttribute('class', classLists);
        }

        return this.utils.injectTag(target, node, this.conditions.place)
    }

    invokeVideoPlayer() {
        this.utils.loadScript('https://cdn.orangeclickmedia.com/tech/libs/ocm-oishb.js', 'text/javascript')
    }

    loadIma() {
        return new Promise((resolve, reject) => {
            try {
                const scripts = this.utils.window.document.getElementsByTagName('script')[0]
                const s = this.utils.window.document.createElement('script')
                s.src = "//imasdk.googleapis.com/js/sdkloader/ima3.js"
                scripts.parentNode.insertBefore(s, scripts)
                s.onload = () => {
                    resolve()
                }
            } catch (e) {
                reject()
            }
        })
    }

    setGlobals() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Setting globals')
        }

        this.utils.window.OCM.ISHB = {
            video_url: this.is_config.video_url,
            debug: (this.config.debug || this.is_config.debug),
            color: this.is_config.color,
            sticky: this.stickyConfig,
            dispose_after_ad: this.is_config.dispose_after_ad,
            device: this.utils.is_mobile ? 'mobile' : 'desktop',
            vast_tag_urls: []
        }
    }

    prebidConfig() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Setting up prebid configuration')
        }

        this.utils.window.ocmispbjs = this.utils.window.ocmispbjs || {que:[]}

        this.utils.window.ocmispbjs.que.push(() => {
            if (typeof this.config.uses_cmp === "undefined" || this.config.uses_cmp) {
                this.utils.window.ocmispbjs.setConfig({
                    consentManagement: {
                        gdpr: {
                            cmpApi: "iab",
                            timeout: this.config.services.header_bidding.bidderTimeout + 20000,
                            defaultGdprScope: true,
                            rules: [
                                {
                                    purpose: "storage",
                                    enforcePurpose: true,
                                    enforceVendor: true,
                                },
                            ],
                        },
                        usp: {
                            cmpApi: "iab",
                            timeout: 100, // US Privacy timeout 100ms
                        },
                    }
                })
            }

            this.utils.window.ocmispbjs.setConfig({
                cache: {
                    url: 'https://prebid.adnxs.com/pbc/v1/cache'
                },
                priceGranularity: this.videoGranularity,
                schain: this.config.schain,
                currency: {
                    "adServerCurrency": this.config.services.header_bidding.adServerCurrency,
                    "conversionRateFile": "https://cdn.orangeclickmedia.com/tech/libs/latest_currencies.txt",
                },
                userSync: {
                    syncsPerBidder: 5,
                    syncDelay: 2500,
                    filterSettings: {
                        iframe: {
                            bidders: '*', // ['pubmatic', 'districtm', 'districtmDMX'], // '*' represents all bidders
                            filter: 'include'
                        }
                    },
                    userIds: [
                        {
                            name: "lotamePanoramaId"
                        },
                        {
                            name: "identityLink",
                            params: {
                                pid: "1258",
                            },
                            storage: {
                                type: "html5",
                                name: "idl_env",
                                expires: 30,
                                refreshInSeconds: 1800
                            }
                        },
                        {
                            name: "unifiedId",
                            params: {
                                url: "//match.adsrvr.org/track/rid?ttd_pid=pubmatic&fmt=json",
                                type: "html5",
                                name: "_unifiedid",
                                expires: 30
                            }
                        },
                        {
                            name: "criteo"
                        },
                        {
                            name: "id5Id",
                            params: {
                                partner: 347             // change to the Partner Number you received from ID5
                            },
                            storage: {
                                type: "html5",
                                name: "id5id",      // create a cookie with this name
                                expires: 90,             // cookie lasts for 90 days
                                refreshInSeconds: 28800 // refresh ID every 8 hours to ensure it's fresh
                            }
                        },
                        {
                            name: "sharedId",
                            storage: {
                                type: "html5",
                                name: "_pubcid",         // create a cookie with this name
                                expires: 365             // expires in 1 years
                            }
                        },

                    ]
                },
                maxRequestsPerOrigin: 4,
                bidderTimeout: this.config.services.header_bidding.bidderTimeout,
                timeoutBuffer: 400,
                useBidCache: true,
                suppressEmptyKeys: true,
                enableSendAllBids: false,
                targetingControls: {
                    alwaysIncludeDeals: true,
                },
                pageUrl: this.utils.window.location.protocol + '//' + this.utils.window.location.hostname,
                ix: {
                    timeout: 2000
                },
                rubicon: {
                    singleRequest: true
                }
            })

            const bidders = [
                { "bidder": "pubmatic", "domain": "pubmatic.com", "id": "157884" },
                { "bidder": "appnexus", "domain": "xandr.com", "id": "10452" },
                { "bidder": "adform", "domain": "adform.com", "id": "2656" },
                { "bidder": "openx", "domain": "openx.com", "id": "540861995" },
                { "bidder": "rubicon", "domain": "rubiconproject.com", "id": "20086" },
                { "bidder": "onetag", "domain": "onetag.com", "id": "5e5558fade26a9e" },
                { "bidder": "criteo", "domain": "criteo.com", "id": "B-060738" },
                { "bidder": "teads", "domain": "teads.tv", "id": "16578" },
                { "bidder": "adyoulike", "domain": "adyoulike.com", "id": "7ddaba905b34014f9bd3fe2c57d6d46e"},
                { "bidder": "gumgum", "domain": "gumgum.com", "id": "14064"},
                { "bidder": "medianet", "domain": "media.net", "id": "8CU32RQ72"},
                { "bidder": "triplelift", "domain": "triplelift.com", "id": "9262"},
                { "bidder": "unruly", "domain": "unrulygroup.com", "id": "6111915185540403805"},
                { "bidder": "yahoossp", "domain": "yahoo.com", "id": "59049"},
            ]

            bidders.forEach((entry) => {
                this.utils.window.ocmispbjs.setBidderConfig({
                    bidders: [entry.bidder],
                    "config": {
                        "schain": {
                            "validation": "relaxed",
                            "config": {
                                "ver":"1.0",
                                "complete": 1,
                                "nodes": [
                                    this.config.schain.config.nodes[0],
                                    {
                                        "asi":`${entry.domain}`,
                                        "sid":`${entry.id}`,
                                        "hp":1
                                    }
                                ]
                            }
                        }
                    }
                })
            })

            this.utils.window.ocmispbjs.bidderSettings = this.setupBidderSettings()

            // window.ocmispbjs.enableAnalytics({provider: 'ocmpba'})
        })
    }

    setupBidderSettings() {
        if (this.config.debug || this.is_config.debug) {
            console.log(LOG_PREFIX + 'Setting up adserverTargeting & bidder settings')
        }

        return {
            standard: {
                suppressEmptyKeys: true,
                adserverTargeting: [
                    {
                        key: "infi_article_count",
                        val: (bidResponse) => {
                            return this.utils.window.OCM.infi_article_count.toString()
                        }
                    },
                    {
                        key: "hb_bidder",
                        val: (bidResponse) => {
                            return bidResponse.bidderCode
                        }
                    }, {
                        key: "hb_adid",
                        val: (bidResponse) => {
                            return bidResponse.adId
                        }
                    }, {
                        key: "hb_pb",
                        val: (bidResponse) => {
                            return bidResponse.pbCg
                        },
                    }, {
                        key: "hb_size",
                        val: (bidResponse) => {
                            return bidResponse.getSize()
                        }
                    }, {
                        key: "hb_format",
                        val: (bidResponse) => {
                            return bidResponse.mediaType
                        }
                    }, {
                        key: "hb_cpm",
                        val: (bidResponse) => {
                            return bidResponse.cpm
                        }
                    }, {
                        key: 'hb_deal',
                        val: (bidResponse) => {
                            return bidResponse.dealId
                        }
                    }, {
                        key: 'hb_source',
                        val: (bidResponse) => {
                            return bidResponse.source
                        }
                    }, {
                        key: 'hb_sf',
                        val: (bidResponse) => {
                            let nsf_bidders = ['justpremium', 'teads', 'gumgum', 'triplelift', 'adhash', 'engageya']
                            let is_video = (bidResponse.mediaType === 'video')
                            let is_tnc_openx = false
                            if (['sportdog.gr', 'startup.gr', 'provocateur.gr', 'newpost.gr'].includes(this.config.domain)) {
                                is_tnc_openx = (bidResponse.bidderCode === 'openx' &&
                                    ['/15637987/sportdog.gr/inline1',
                                        '/21748129265/startup.gr/billboard1',
                                        '/15637987/provoc_right_1',
                                        '/15637987/newpost_right_1'].includes(bidResponse.adUnitCode))
                            }
                            return (is_tnc_openx || is_video || nsf_bidders.includes(bidResponse.bidderCode)) ? 'false' : 'true';
                        }
                    }, {
                        key: 'hb_cache_id',
                        val: (bidResponse) => {
                            return bidResponse.videoCacheKey
                        }
                    }, {
                        key: 'hb_uuid',
                        val: (bidResponse) => {
                            return bidResponse.videoCacheKey
                        }
                    }
                ]
            }
        }
    }
}
