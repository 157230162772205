const LOG_PREFIX = '[OCM][Engageya] '

module.exports = class Engageya {
    utils
    config
    engageya_config

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.engageya_config = (this.utils.is_mobile) ? config.services.engageya.mobile : config.services.engageya.desktop
    }

    run() {
        if (this.config.debug || this.config.services.engageya.debug) {
            console.log(LOG_PREFIX + 'Running...');
        }

        let node = this.utils.determineInjectionTarget(this.engageya_config.conditions.selector, this.engageya_config.conditions.position, this.engageya_config.conditions.count_gt, this.engageya_config.conditions.word_count, this.engageya_config.conditions.words_gt)
        if (node) {
            let cr_div = document.createElement('div');
            cr_div.id = 'OCM_CR_WIDGET_' + this.engageya_config.wid

            let cr_script = document.createElement('script');
            cr_script.text = '(function(E,n,G,A,g,Y,a){E[\'EngageyaObject\']=g;E[g]=E[g]||function(){ (E[g].q=E[g].q||[]).push(arguments)},E[g].l=1*new Date();Y=n.createElement(G), a=n.getElementsByTagName(G)[0];Y.async=1;Y.src=A;a.parentNode.insertBefore(Y,a)})\n' +
                '(window,document,\'script\',\'//widget.engageya.com/engageya_loader.js\',\'__engWidget\')\n' +
                '__engWidget(\'createWidget\',{wwei:\'' + cr_div.id + '\',pubid:' + this.engageya_config.pubid + ',webid:' + this.engageya_config.webid + ',wid:' + this.engageya_config.wid + '});'

            if (this.utils.injectTag(node, cr_div, this.engageya_config.conditions.place)) {
                cr_div.appendChild(cr_script)
            }

            cr_div.appendChild(cr_script)
        } else {
            if (this.config.debug || this.config.services.engageya.debug) {
                console.log(LOG_PREFIX + 'Could not find defined selector: ' + this.engageya_config.selector);
            }
        }
    };
}
