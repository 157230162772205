const LOG_PREFIX = '[OCM][GPT] '
let gpt = (config) => {
    let win = config.hasOwnProperty('use_window_top') ? ( config.use_window_top  ? window.top:  window ) : window.top

    win.googletag = win.googletag || {}
    googletag.cmd = googletag.cmd || []

    if (config.debug || config.services.gpt.debug) {
        console.log(LOG_PREFIX + 'Loading GPT library...')
    }

    let element = document.createElement('script')
    let firstScript = document.getElementsByTagName('script')[0]
    let url = '//securepubads.g.doubleclick.net/tag/js/gpt.js'

    element.async = true
    element.type = 'text/javascript'
    element.src = url
    firstScript.parentNode.insertBefore(element, firstScript)

    googletag.cmd.push(() => {
        if (config.services.gpt.collapseEmptyDivs[0]) {
            if (config.services.gpt.collapseEmptyDivs[1]) {
                googletag.pubads().collapseEmptyDivs(true)
            } else {
                googletag.pubads().collapseEmptyDivs()
            }
        }

        if (config.services.gpt.enableSingleRequest) {
            googletag.pubads().enableSingleRequest()
        }

        if (config.services.gpt.setCentering) {
            googletag.pubads().setCentering(true)
        }

        if (config.services.gpt.disableInitialLoad) {
            googletag.pubads().disableInitialLoad()
        }

        googletag.enableServices()
    })
}

module.exports = gpt
