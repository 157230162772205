const LOG_PREFIX = '[OCM][Teads Analytics]'

let teadsAnalytics = (config) => {

    let win =  config.hasOwnProperty('use_window_top') ? ( config.use_window_top  ? window.top:  window ) : window.top

    // Create the global config for the teads analytics
    win.teads_analytics = win.teads_analytics || {};

    // Set up the publisher id to the teads analytics config
    win.teads_analytics.analytics_tag_id = `PUB_${config.services.teads_analytics.publisher_id}`;

    // Pass a share function
    win.teads_analytics.share = win.teads_analytics.share || function() {
        (win.teads_analytics.shared_data = win.teads_analytics.shared_data || []).push(arguments)
    };

    // Create a script element to load ad script
    let teadsAnalyticsScript = document.createElement('script');

    // Update src based on link given to you by your PS manager
    teadsAnalyticsScript.src = 'https://a.teads.tv/analytics/tag.js';

    // Insert script in the head
    document.head.appendChild(teadsAnalyticsScript);

    if (config.debug || config.services.teads_analytics.debug) {
        console.info(LOG_PREFIX + 'Loaded')
    }
};

module.exports = teadsAnalytics;