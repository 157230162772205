let ua = navigator.userAgent || navigator.vendor || window.opera;
let isFacebookApp = (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
let isIphone = (ua.indexOf("iPhone") > -1)
let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
let isFirefox = typeof InstallTrigger !== 'undefined'
// let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
//     return p.toString() === "[object SafariRemoteNotification]"
// })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)) || navigator.userAgent.indexOf('iPhone') > -1
let isSafari = window.safari !== undefined
let isIE = /*@cc_on!@*/!!document.documentMode
let isEdge = !isIE && !!window.StyleMedia
let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
let isBlink = (isChrome || isOpera) && !!window.CSS

let browser = {
    isFacebookApp: isFacebookApp,
    isOpera: isOpera,
    isFirefox: isFirefox,
    isIphone: isIphone,
    // Safari 3.0+ "[object HTMLElementConstructor]"
    isSafari: isSafari,
    // Internet Explorer 6-11
    isIE: isIE,
    // Edge 20+
    isEdge: isEdge,
    // Chrome 1 - 71
    isChrome: isChrome,
    // Blink engine detection
    isBlink: isBlink
};
