const LOG_PREFIX = '[OCM][Reaper] '
const COLLECTOR_ENDPOINT = 'https://ue.adsquirrel.ai/insert'
const SYNC_ENDPOINT = '//pba.orangeclickmedia.com/usync'
const Cookies = require('js-cookie')
const Bowser = require('bowser')
const uuid = require('uuid')

module.exports = class Reaper {
    utils
    config
    reaper_config
    _oid
    _oeid
    _oeid_time
    data
    ip
    device_access

    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this._oid = null
        this._oeid = uuid.v4() // create the event id that will be bind with the user
        this.utils.window.OCM._oeid = this._oeid
        this._oeid_time = (new Date()).getTime()
        this.reaper_config = config.services.reaper
        this.data = {}
        this.device_access = true
        this.ip = null

        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + `Creating the oeid(${this._oeid}) at the constructor`);
        }

        // add to window the site language from reaper
        this.utils.window.OCM.site_language = this?.reaper_config?.language || null;
    }

    run() {
        if (!this.utils.window.OCM?.hasPurposeOneConsent && this.config.services.iab_compliance.active) {
            if (this.config.debug || this.reaper_config.debug) {
                console.warn(LOG_PREFIX + 'Device access was denied. Forcing first party.')
            }
            this.device_access = false
        }

        this.targetEventId()

        this.getUserIP()
            .catch(() => {
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'Failed to fetch IP')
                }
                // this.utils.log.warning('reaper', 'getUserIP() reaper.js:46', 'Failed to fetch IP', this.config.build, this.utils.hostname, this.utils.url)
            }).finally(() => {
                this.collect().then(() => {
                    if (this.config.debug || this.reaper_config.debug) {
                        console.log(LOG_PREFIX + 'Collected: ', this.data)
                    }

                    this.send()
                }).catch(error => {
                    console.error(LOG_PREFIX + 'Failed at data collection', error)
                    // this.utils.log.error('reaper', 'Could not collect data from browser', error, this.config.build, this.utils.hostname, this.utils.url)
                })
            })
    }

    async getUserIP() {
        return new Promise(async (resolve, reject) => {
            fetch('https://www.cloudflare.com/cdn-cgi/trace')
                .then(response => response.text())
                .then((cfdata) => {
                    try {
                        cfdata = cfdata.trim().split('\n').reduce(function (obj, pair) {
                            pair = pair.split('=');
                            return obj[pair[0]] = pair[1], obj;
                        }, {});
                        this.ip = cfdata.ip
                        resolve()
                    } catch (error) {
                        fetch('https://api64.ipify.org')
                            .then(response => response.text())
                            .then(ip => {
                                this.ip = ip
                                resolve()
                            })
                            .catch(error => {
                                reject()
                            })
                    }
                }).catch((error) => {
                    fetch('https://api64.ipify.org')
                        .then(response => response.text())
                        .then(ip => {
                            this.ip = ip
                            resolve()
                        })
                        .catch(error => {
                            reject()
                        })
                })
        })
    }

    runUserEngagement(oid, oeid) {
        if (this.config.services?.user_engagement?.active) {
            const UserEngagement = require('./user_engagement')
            const engagement = new UserEngagement(this.utils, this.config, oid, oeid);
            engagement.run()
        }
    }

    hasAlreadyRun() {
        return this.utils.window.sessionStorage.getItem('has_reaper_already_run') || false;
    }

    cleanSessionStorageOnUnload() {
        this.utils.window.addEventListener('beforeunload', () => {
            console.log(LOG_PREFIX + 'Removing reaper key from session storage.');
            this.utils.window.sessionStorage.removeItem('has_reaper_already_run');
        });
    }

    targetEventId() {
        this.utils.window.googletag = this.utils.window.googletag || {cmd: []}

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().setTargeting('oeid', [this._oeid])
        })
    }

    async collect() {
        return new Promise((resolve, reject) => {
            if (this.config.debug || this.reaper_config.debug) {
                console.log(LOG_PREFIX + 'Collecting data...')
            }

            let cookie_type = 'third'

            const _browser = Bowser.parse(this.utils.window.navigator.userAgent);

            if (!this.device_access) {
                cookie_type = 'first'
            } else {
                if (_browser.os.name === 'iOS' && parseFloat(_browser.os.version) >= 14) {
                    cookie_type = 'first'
                } else if (_browser.browser.name === 'Safari' && parseFloat(_browser.browser.version) >= 13.1) {
                    cookie_type = 'first'
                } else {
                    cookie_type = (['Firefox', 'Brave'].includes(_browser.browser.name)) ? 'first' : 'third'
                }
            }

            if (_browser.platform.type === 'bot' && !this.utils.window.location.hostname.includes('newsbomb.gr')) {
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'Bot detected, terminating process')
                }
                reject('Bot detected, terminating process')
            }

            if (this.config.debug || this.reaper_config.debug) {
                console.log(LOG_PREFIX + 'Cookie type decision:', cookie_type)
                console.log('OS:', _browser.os.name + ' ' + _browser.os.version)
                console.log('Browser:', _browser.browser.name + ' ' + _browser.browser.version)
            }

            this.reap(cookie_type).then(() => {
                this.data = {
                    ip: this.ip,
                    oid: this._oid,
                    oeid: this._oeid,
                    oeid_time: this._oeid_time,
                    eids: "",
                    // parrableId: (typeof window.top.PWT !== 'undefined') ? window.top.PWT.getUserIds().parrableId.eid : '',
                    // tdid: (typeof window.top.PWT !== 'undefined') ? window.top.PWT.getUserIds().tdid : '',
                    cookie_type: cookie_type,
                    os: `${_browser.os.name || ""}/${_browser.os.versionName || ""}/${_browser.os.version || ""}`,
                    browser: `${_browser.browser.name}/${_browser.browser.version}` || "",
                    // userAgent: window.navigator.userAgent,
                    platform: _browser.platform.type || "",
                    pageType: this.utils.window.OCM.pageType,
                    url: `${this.utils.window.location.protocol}//${this.utils.window.location.hostname + this.utils.window.location.pathname}`,
                    language: this.utils.window.navigator.language,
                    hostname: this.utils.window.location.hostname.replace('www.', ''),
                    searchParams: this.utils.window.location.search,
                    city: this.utils.window.xtbClient?.cookies.city ?? "",
                    pixelRatio: this.utils.window.devicePixelRatio ?? null,
                    connection: this.getConnectionSpeed(),
                    referrer: document.referrer,
                    viewportSize: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
                    windowSize: `${this.utils.window.outerWidth}x${this.utils.window.outerHeight}`,
                    site_language: this?.reaper_config?.language || '-'
                }

                // Run user engagement after the reaper has started
                this.runUserEngagement(this._oid, this._oeid)

                // Wait for ocmpbjs.getUserIdsAsEids()
                let maxUserIdsTries = 10
                let waitForUserIds = () => {
                    if (this.config.debug || this.reaper_config.debug) {
                        console.log(LOG_PREFIX + 'running waitForUserIds', maxUserIdsTries)
                    }

                    if (maxUserIdsTries <= 0) {
                        if (this.config.debug || this.reaper_config.debug) {
                            console.log(LOG_PREFIX + 'maxUserIdsTries zeroed out, resolving')
                        }
                        resolve()
                        return
                    }

                    if (!window.ocmpbjs) {
                        maxUserIdsTries--
                        setTimeout(() => {
                            waitForUserIds()
                        }, 200)
                    } else {
                        if (typeof window.ocmpbjs.getUserIdsAsEids === 'function' && window.ocmpbjs.getUserIdsAsEids().length) {
                            this.data.eids = JSON.stringify(window.ocmpbjs.getUserIdsAsEids())
                            resolve()
                        } else {
                            maxUserIdsTries--
                            setTimeout(() => {
                                waitForUserIds()
                            }, 200)
                        }
                    }
                }

                waitForUserIds()

            }).catch((error) => {
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'Failed reaping, terminating process', error)
                }
                reject(error)
            })
        })
    }

    /**
     * Checks if the daily sync has been already made to Jaina
     * @returns {boolean|*}
     */
    isDailyCallMade() {
        const date = this.utils.window.localStorage.getItem('ocm_sync_date');
        if (date) {
            return this.isToday(new Date(date));
        }
        return false;
    }

    /**
     * Verifies if the given day is today
     * @param someDate
     * @returns {boolean}
     */
    isToday(someDate) {
        const today = new Date()
        return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear()
    }

    reap(type) {
        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + 'Reaping...')
        }

        return new Promise(async (resolve, reject) => {
            let end = (result) => {
                if (result) {
                    resolve(result)
                } else {
                    reject()
                }
            }

            /**
             * If the reaper has not already made the daily sync then we first sync with Jain, and then we crawl
             * the site else we move instantly to crawl
             */
            if (!this.isDailyCallMade()) {
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'Running daily sync')
                }
                this.thirdPartyCall(type, (result) => {
                    if (result) {
                        this.utils.window.localStorage.setItem('ocm_sync_date', new Date());
                        this.saveOidInStorage()
                    } else {
                        this.deleteOidFromStorage()
                    }
                    end(result)
                })
            } else {
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'Daily sync has already run')
                }
                this._oid = Cookies.get("_oid") || this.utils.window.localStorage.getItem('_oid') || null
                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + '_oid from storage = ', this._oid)
                }

                if (this._oid) {
                    end(true)
                    return
                }

                if (this.config.debug || this.reaper_config.debug) {
                    console.log(LOG_PREFIX + 'this._oid = ' + this._oid)
                    console.log(LOG_PREFIX + 'cookie_type = ' + type)
                }

                this.thirdPartyCall(type, (result) => {
                    if (result) {
                        this.saveOidInStorage()
                    } else {
                        this.deleteOidFromStorage()
                    }

                    end(result)
                })
            }
        })
    }

    saveOidInStorage() {
        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + 'Saving _oid in storage')
        }
        Cookies.set("_oid", this._oid, {
            expires: 3650,
            domain: '.' + this.utils.window.location.host.replace('www.', ''),
            path: '/',
            Secure: true,
            HttpOnly: false
        })
        this.utils.window.localStorage.setItem('_oid', this._oid)
    }

    deleteOidFromStorage() {
        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + 'Deleting _oid in storage')
        }

        Cookies.remove("_oid", {
            domain: '.' + this.utils.window.location.host.replace('www.', ''),
            path: '/',
            Secure: true,
            HttpOnly: false
        })
        this.utils.window.localStorage.removeItem('_oid')
    }

    thirdPartyCall(type, callback) {
        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + 'Calling for 3rd party cookie')
        }

        let xhr = new XMLHttpRequest();
        xhr.open("GET", SYNC_ENDPOINT + '?type=' + type);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.withCredentials = true;
        xhr.send();

        xhr.onloadend = () => {
            this._oid = JSON.parse(xhr.response)._oid
            callback(true)
        }

        xhr.onerror = () => {
            this.deleteOidFromStorage()
            callback(false)
        }
    }

    getConnectionSpeed() {
        let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (!connection) return null;
        if (connection.saveData) return null;
        switch (connection.effectiveType) {
            case "4g":
                return 3
            case "3g":
                return 2
            case "2g":
            case "slow-2g":
                return 1
            default:
                return null
        }
    }

    send() {
        if (this.config.debug || this.reaper_config.debug) {
            console.log(LOG_PREFIX + 'Sending: ', this.data)
        }

        let xhr = new XMLHttpRequest();
        xhr.open("POST", COLLECTOR_ENDPOINT);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(this.data));
        xhr.onerror = () => {
            // this.utils.log.error('reaper', 'Failed at sending the data to https://ue.adsquirrel.ai/insert', '', this.config.build, this.utils.hostname, this.utils.url)
        }
    }
}
